import { Box, Button } from '@mui/material';
import React from 'react';

import { ChatController } from '../chat-controller';
import { SelectActionRequest, SelectActionResponse } from '../chat-types';

const printStars = (val: string): string => {
  let stars = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Number(val.replace('v', '')) + 1; i++) {
    stars += '⭐';
  }
  return stars;
};

export function MuiRating({
  chatController,
  actionRequest,
}: {
  chatController: ChatController;
  actionRequest: SelectActionRequest;
}): React.ReactElement {
  const [answerValue, setAnswerValue] = React.useState<string>();

  const chatCtl = chatController;
  const setResponse = React.useCallback(
    (value: string): void => {
      const option = actionRequest.options.find((o) => o.value === value);
      if (!option) {
        throw new Error(`Unknown value: ${value}`);
      }
      setAnswerValue(option.value);
      const res: SelectActionResponse = {
        type: 'select',
        value: printStars(option.value),
        option,
      };
      setTimeout(() => {
        chatCtl.setActionResponse(actionRequest, res);
      }, 800);
    },
    [actionRequest, chatCtl],
  );

  return (
    <Box
      className="selectcontainer"
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        '& > *': {
          flex: '0 0 auto',
          maxWidth: '100%',
        },
        '& > * + *': {
          mt: 1,
        },
      }}
    >
      {actionRequest.options.map((o) => (
        <Button
          key={actionRequest.options.indexOf(o)}
          type="button"
          value={o.value}
          onClick={(e): void => setResponse(e.currentTarget.value)}
          color="primary"
          className="single-button"
          style={{ background: '#eaeaea' }}
        >
          <div
            className={`ratingStar ${
              Number(answerValue?.replace('v', '')) >=
              Number(o.value.replace('v', ''))
                ? 'orange'
                : ''
            }`}
          />
        </Button>
      ))}
    </Box>
  );
}
