// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-string-refs */
/* eslint-disable eslint-comments/no-duplicate-disable */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-continue */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable promise/always-return */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-await-in-loop */
import {
  Box,
  CssBaseline,
  Divider,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import axios from 'axios';
import React from 'react';

import { ChatController, MuiChat } from './chat';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#4a86ba',
    },
  },
});

const domain = window.location.hostname;

const urlParams = new URLSearchParams(window.location.search);
const ID = urlParams.get('id');
let PROJECT = urlParams.get('p');
if (!PROJECT) PROJECT = '';
axios.defaults.baseURL =
  domain === 'localhost'
    ? 'http://localhost:4500/'
    : 'https://dataexpert.hu/chatbottestcors/';

const serialize = (form: any) => {
  // Create an array
  const arr: any = [];

  // Loop through each field in the form
  Array.prototype.slice.call(form.elements).forEach((field) => {
    // Skip some fields we don't need
    if (
      !field.name ||
      field.disabled ||
      ['file', 'reset', 'submit', 'button'].includes(field.type)
    )
      return;

    // Handle multi-select fields
    if (field.type === 'select-multiple') {
      // Loop through the options and add selected ones
      Array.prototype.slice.call(field.options).forEach((option) => {
        if (!option.selected) return;
        option.push(
          `${encodeURIComponent(field.name)}=${encodeURIComponent(
            option.value,
          )}`,
        );
      });
    }

    // If it's a checkbox or radio button and it's not checked, skip it
    if (['checkbox', 'radio'].includes(field.type) && !field.checked) return;

    // Add the field name and value to the array
    arr.push(
      `${encodeURIComponent(field.name)}=${encodeURIComponent(field.value)}`,
    );
  });

  // Return the array items as a string
  return arr.join('&');
};

const getQuestion = async (form: any, chatCtl: ChatController, formdata: any) =>
  new Promise((resolve, reject) => {
    const FORM = form?.querySelector('form');

    const BODY = form?.querySelector('body');
    const optionsSingle = BODY?.querySelectorAll(
      '.cf-single-answer,.cf-radio-answer,.cf-star-rating-item',
    );
    const optionsMulti = BODY?.querySelectorAll(
      '.cf-multi-answer,.cf-checkbox-answer',
    );

    const optionsRating = BODY?.querySelectorAll('.cf-star-rating-item');

    let qid = BODY.querySelector('.cf-question--single');

    if (optionsRating.length > 0)
      qid = BODY.querySelector('.cf-question--star-rating-single');

    optionsSingle.forEach((el: any, index: number) => {
      const input = document.createElement('input');
      input.type = 'radio';
      input.setAttribute('name', qid?.id);
      input.setAttribute(
        'value',
        el.id.split('_').length > 2 ? el.id.split('_')[2] : el.id.split('_')[1],
      );
      console.log(input);
      // el.setAttribute('name', qid?.id);
      el.append(input);
      FORM?.append(input);
    });

    optionsMulti.forEach((el: any, index: number) => {
      const input = document.createElement('input');
      input.type = 'checkbox';
      input.setAttribute('name', el.id);
      input.setAttribute('value', '1');
      if (el.querySelector('.cf-multi-answer__other-input')) {
        const oth = el.querySelector('.cf-multi-answer__other-input');
        oth.setAttribute('name', oth.id);
      }
      el.append(input);
      FORM?.append(input);
    });

    const inputFWD = document.createElement('input');
    inputFWD.type = 'hidden';
    inputFWD.setAttribute('name', '__fwd');
    inputFWD.setAttribute('value', '1');

    // FORM.append(inputFWD)
    const textinputs = BODY.querySelectorAll(
      '.cf-open-answer__input,.cf-open-list-answer__input,.cf-numeric-list-answer__input,.cf-text-area,.cf-text-box',
    );
    const qtext = BODY?.querySelector('.cf-question__text,.cf-text-area');

    if (textinputs.length === 1) {
      let textQID = BODY?.querySelector('.cf-question')?.id;

      if (textQID === 'q8') textQID = 'q8_1';
      textinputs[0]?.setAttribute('name', textQID);
      FORM.append(textinputs[0]);
      // cf-question--open
    }

    const textinputsArr = Array.prototype.slice.call(textinputs);
    let txt: any = document.createElement('input');

    let tt =
      qtext?.innerHTML.indexOf('region') > -1
        ? `${qtext?.innerHTML}  [multiple-choice]`
        : qtext?.innerHTML;

    // chatCtl.addTyping({
    //   type: 'text',
    //   content: '...',
    //   self: false,
    //   avatar: '-',

    // }).then(async () => {
    //   // eslint-disable-next-line promise/no-nesting
    //   setTimeout(async () => {
    // eslint-disable-next-line promise/no-nesting

    const altT = BODY?.querySelector('.cf-numeric-list-answer__text');
    // eslint-disable-next-line prefer-const
    let errorx = BODY?.querySelector('.cf-error-list__item');

    // eslint-disable-next-line prefer-const
    let errorText = errorx?.innerHTML || '';
    console.log('error', errorx);
    chatCtl
      .addMessage({
        type: 'text',
        content: qtext?.innerHTML
          ? `${tt}<br>${errorText}`
          : '... (please type in anything)',
        self: false,
        avatar:
          // 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1200px-User-avatar.svg.png',
          // https://www.business2community.com/wp-content/uploads/2018/06/bots_1530071229-600x600.png'
          'https://dataexpert.hu/chatbottestcors/chatimage.png',
      })
      .then(async () => {
        if (
          optionsSingle.length === 0 &&
          optionsMulti.length === 0 &&
          textinputsArr.length === 0
        ) {
          const fd = serialize(FORM);
          const da = encodeURIComponent(JSON.stringify(fd));
          // console.log(da)
          const response = await axios.get(
            `post.php?id=${ID}&dat=${da}&p=${PROJECT}`,
          );
          resolve(response.data);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
    //   }, 1000);
    // }).catch((error: any) => {
    //   console.log(error);
    // });

    setTimeout(() => {
      if (
        optionsSingle.length === 0 &&
        optionsMulti.length === 0 &&
        textinputsArr.length > 0
      ) {
        if (altT) {
          tt = `${tt}<br>${altT.innerHTML}`;
        }
        chatCtl
          .setActionRequest({
            type: 'text',
            content: `Question `,
            self: false,
            inputtype: 'text',
            texttype:
              // eslint-disable-next-line no-nested-ternary
              BODY?.querySelectorAll('.cf-numeric-list-answer__input')?.length >
              0
                ? 'number'
                : BODY?.querySelectorAll('.cf-question--email')?.length > 0
                ? 'email'
                : 'text',
            avatar: '-',
            placeholder: altT ? altT.innerHTML : '',
          })
          .then(async (data) => {
            textinputsArr.forEach((input: any, index: number) => {
              // eslint-disable-next-line no-param-reassign
              txt = FORM?.querySelectorAll(
                '.cf-open-answer__input,.cf-numeric-list-answer__input,.cf-text-area,.cf-text-box',
              )[index] as HTMLTextAreaElement;
              // eslint-disable-next-line no-param-reassign
              txt.value = data.value;
              // eslint-disable-next-line no-param-reassign
              // txt.text = data.value;
              // console.log(txt);
            });

            const fd = serialize(FORM);
            // console.log(fd);
            const da = encodeURIComponent(JSON.stringify(fd));
            const response = await axios.get(
              `post.php?id=${ID}&p=${PROJECT}&dat=${da}`,
            );

            resolve(response.data);
          })
          .catch((error) => {
            // console.log(error)
          });
      } else if (optionsRating.length > 0 && textinputsArr.length === 0) {
        // sd
        const opts = Array.prototype.slice.call(optionsSingle);
        chatCtl
          .setActionRequest({
            type: 'rating',
            options: opts?.map((option: any, index) => ({
              value: `v${index}`,
              text: option.textContent,
            })),
          })
          .then(async (data: any) => {
            // console.log(FORM)
            // console.log(data.option.value.replace("v",""))
            // console.log(data);
            let resp = null;
            resp = data.options
              ? data.options[0].value.replace('v', '')
              : data.option.value.replace('v', '');

            txt = FORM?.querySelectorAll('input[type=radio]')[Number(resp)];
            txt.checked = true;
            console.log(txt);
            const fd = serialize(FORM);
            const da = encodeURIComponent(JSON.stringify(fd));
            const response = await axios.get(
              `post.php?id=${ID}&p=${PROJECT}&dat=${da}`,
            );
            resolve(response.data);
          })
          .catch((error) => {
            // console.log(error)
          });
      } else if (optionsSingle.length > 0) {
        /** ********************/
        // IF RADIO
        /** ********************/
        const opts = Array.prototype.slice.call(optionsSingle);

        chatCtl
          .setActionRequest({
            type: 'select',
            options: opts?.map((option: any, index) => ({
              value: `v${
                option.id.split('_').length > 2
                  ? option.id.split('_')[2]
                  : option.id.split('_')[1]
              }`,
              text: option.textContent,
            })),
          })
          .then(async (data: any) => {
            // console.log(FORM)
            // console.log(data);
            // console.log(data.option.value.replace("v",""))
            let resp = null;
            resp = data.options
              ? data.options[0].value.replace('v', '')
              : data.option.value.replace('v', '');
            const tempTxt = resp;
            // let changeit = false;

            if (!Number(resp)) {
              resp = 96;
              // changeit = true;
              const tinput = document.createElement('input');
              tinput.type = 'text';
              tinput.setAttribute('name', 'q6_96_other');
              tinput.setAttribute('value', tempTxt);

              resp = '96';
              FORM.append(tinput);
            }

            const qstSample = FORM?.querySelectorAll(`input[type=radio]`)[0];
            const name = qstSample.getAttribute('name');
            txt = FORM?.querySelectorAll(
              `input[type=radio][value='${resp}']`,
            )[0];

            txt.checked = true;

            let fd = serialize(FORM);
            fd = fd.replace('&q6=&', `&`);

            // if last 4 char is &q4=, cut
            if (fd.slice(-4) === '&q6=') {
              fd = fd.slice(0, -4);
            }

            const da = fd;

            // FORM.querySelectorAll(``).forEach((input: any) => {
            // fd = fd.replace('&q4=&', ``);
            console.log(fd);
            const response = await axios.get(
              `post.php?id=${ID}&p=${PROJECT}&dat=${encodeURIComponent(da)}`,
            );
            resolve(response.data);
          })
          .catch((error) => {
            // console.log(error)
          });
      } else if (optionsMulti.length > 0 && textinputsArr.length === 0) {
        /** ********************/
        // IF CHECKBOX
        /** ********************/
        const opts = Array.prototype.slice.call(optionsMulti);
        // console.log("options", opts[0].classList.contains('cf-multi-answer--selected'))
        chatCtl
          .setActionRequest({
            type: 'multi-select',
            options: opts?.map((option: any, index) => ({
              value: `v${index}`,
              text: option.textContent,
              preselected: option.classList.contains(
                'cf-multi-answer--selected',
              ),
              exclusive: option.classList.contains(
                'cf-multi-answer--exclusive',
              ),
            })),
          })
          .then(async (data: any) => {
            // console.log(data.option.value.replace("v",""))
            // let resp = null
            // resp = data.options ? data.options[0].value.replace("v", "") : data.option.value.replace("v", "");

            data.options.forEach((option: any, index: number) => {
              // eslint-disable-next-line no-param-reassign
              txt = FORM?.querySelectorAll('input[type=checkbox]')[
                Number(option.value.replace('v', ''))
              ];
              txt.checked = true;
            });
            if (data.otherValue) {
              txt = FORM?.querySelectorAll(
                '.cf-multi-answer__other-input',
              )[0] as HTMLTextAreaElement;
              txt.value = data.otherValue;
            }

            // alert()
            // console.log(data.options)
            // txt = FORM?.querySelector('input[type=checkbox]');
            // console.log(txt)
            // txt.checked = true
            const fd = serialize(FORM);
            const da = encodeURIComponent(JSON.stringify(fd));
            const response = await axios.get(
              `post.php?id=${ID}&p=${PROJECT}&dat=${da}`,
            );
            resolve(response.data);
          })
          .catch((error) => {
            // console.log(error)
          });
      } else if (optionsMulti.length > 0 && textinputsArr.length > 0) {
        /** ********************/
        // IF CHECKBOX with other, or TEXTAREA with DK / NA
        /** ********************/
        const opts = Array.prototype.slice.call(optionsMulti);
        chatCtl
          .setActionRequest({
            type: 'multitextcheckbox',
            options: opts?.map((option: any, index) => ({
              value: `v${index}`,
              text: option.textContent,
            })),
          })
          .then(async (data: any) => {
            // console.log(FORM)
            // console.log(data.option.value.replace("v",""))
            // let resp = null
            // resp = data.options ? data.options[0].value.replace("v", "") : data.option.value.replace("v", "");

            if (data.options.length > 0) {
              txt = FORM?.querySelector('input[type=checkbox]');
              txt.checked = true;
            } else {
              txt = FORM?.querySelector(
                'input[type=text], textarea, input[type=email]',
              );
              txt.value = data.value;
              console.log(txt);
            }

            const fd = serialize(FORM);
            const da = encodeURIComponent(JSON.stringify(fd));
            const response = await axios.get(
              `post.php?id=${ID}&p=${PROJECT}&dat=${da}`,
            );
            resolve(response.data);
          })
          .catch((error) => {
            // console.log(error)
          });
      }
    }, 1000);
  });

export function App(): React.ReactElement {
  const [chatCtl] = React.useState(
    new ChatController({
      showDateTime: true,
    }),
  );

  React.useMemo(async () => {
    // const postdata = { id:"asd" };
    // get link param ID

    const response = await axios.get(`index.php?id=${ID}&p=${PROJECT}`);

    // eslint-disable-next-line unicorn/prefer-array-some
    if (response.data.includes('Object moved')) {
      const chatcont = document.querySelector('.chatcont');
      if (chatcont)
        chatcont.innerHTML = `<div style="text-align:center; padding: 20px; font-size: 20px; font-weight: bold;">The specified survey cannot be found.</div>`;
    }
    echo(chatCtl, response.data);
  }, [chatCtl]);

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <div>
        <Box sx={{ height: '100%' }}>
          <Box
            className="chatcont"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
              bgcolor: 'background.default',
            }}
          >
            <Divider />
            <Box sx={{ flex: '1 1 0%', minHeight: 0 }}>
              <MuiChat chatController={chatCtl} />
            </Box>
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
}

async function echo(chatCtl: ChatController, qst: any): Promise<void> {
  const i = 0;
  let formdata: any = 0;
  let qq = qst;
  const parser = new DOMParser();
  // const needpost = false;
  // const actualQuestion = false;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const html: any = parser.parseFromString(qq, 'text/html');
    const form: any = html.querySelector('form');

    if (form) {
      formdata = serialize(form);
    } else {
      return;
      break;
    }
    // console.log(form)
    // eslint-disable-next-line no-plusplus
    const test = await getQuestion(html, chatCtl, formdata);
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 1600);
    qq = test;
  }
}
