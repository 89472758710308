// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Icon, TextField } from '@mui/material';
import React, { useEffect } from 'react';

import { ChatController } from '../chat-controller';
import {
  MultiSelectActionRequest,
  MultiSelectActionResponse,
} from '../chat-types';

export function MuiMultiSelectInput({
  chatController,
  actionRequest,
}: {
  chatController: ChatController;
  actionRequest: MultiSelectActionRequest;
}): React.ReactElement {
  const chatCtl = chatController;
  const [values, setValues] = React.useState<string[]>([]);
  const [otherValue, setOtherValue] = React.useState(
    actionRequest.defaultValue,
  );

  const handleSelect = React.useCallback(
    (value: string, exclusive: boolean): void => {
      if (exclusive) {
        setValues([value]);
      } else {
        const exclusiveVal = actionRequest.options?.find(
          (o) => o.exclusive,
        )?.value;
        if (!values.includes(value)) {
          setValues([...values.filter((el) => exclusiveVal !== el), value]);
        } else {
          setValues(
            values
              .filter((el) => exclusiveVal !== el)
              .filter((v) => v !== value),
          );
        }
      }
    },
    [actionRequest.options, values],
  );

  const setResponse = React.useCallback((): void => {
    const options = actionRequest.options.filter((o) =>
      values.includes(o.value),
    );

    const res: MultiSelectActionResponse = {
      type: 'multi-select',
      value: options.map((o) => o.text).toString(),
      otherValue,
      options,
    };
    chatCtl.setActionResponse(actionRequest, res);
    setValues([]);
  }, [actionRequest, chatCtl, values, otherValue]);

  const sendButtonText = actionRequest.sendButtonText
    ? actionRequest.sendButtonText
    : 'Verstuur';

  useEffect(() => {
    const arr: React.SetStateAction<string[]> = [];
    actionRequest.options.forEach((o) => {
      if (o.preselected) {
        arr.push(o.value);
      }
    });
    setValues(arr);
  }, [actionRequest.options]);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
    }
    setOtherValue(e.target.value);
  };
  return (
    <Box
      className="selectcontainer"
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
          flex: '0 0 auto',
          maxWidth: '100%',
        },
      }}
    >
      {actionRequest.options.map((o) => (
        <>
          <Button
            sx={{
              my: 0.2,
            }}
            key={actionRequest.options.indexOf(o)}
            type="button"
            value={o.value}
            onClick={(e): void =>
              handleSelect(e.currentTarget.value, o.exclusive ?? false)
            }
            variant={!values.includes(o.value) ? 'outlined' : 'contained'}
            color="primary"
          >
            {o.text.trim() === '' ? 'anders' : o.text}
          </Button>
          {o.text.trim() === '' && values.includes(o.value) && (
            <TextField
              key={`ot${actionRequest.options.indexOf(o)}`}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              multiline
              inputProps={{ onKeyUp: handleKeyDown }}
              variant="outlined"
              maxRows={10}
            />
          )}
        </>
      ))}
      <Button
        type="button"
        onClick={setResponse}
        disabled={values.length === 0}
        variant="contained"
        color="primary"
        startIcon={<Icon>send</Icon>}
      >
        {sendButtonText}
      </Button>
    </Box>
  );
}
