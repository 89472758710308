// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box, Button, Icon, TextField } from '@mui/material';
import React from 'react';

import { ChatController } from '../chat-controller';
import { SelectActionRequest, SelectActionResponse } from '../chat-types';

export function MuiSelectInput({
  chatController,
  actionRequest,
}: {
  chatController: ChatController;
  actionRequest: SelectActionRequest;
}): React.ReactElement {
  const chatCtl = chatController;

  // respp state any

  const [rvalue, setRValue] = React.useState<any>(null);
  const [tvalue, setTValue] = React.useState<any>(null);

  const setOther = (value: string): void => {
    setTValue(value);
    setRValue(null);
  };

  const sendResponse = React.useCallback(
    (value: string, othervalue: string): void => {
      // eslint-disable-next-line eqeqeq
      const option = actionRequest.options.find((o) => o.value == value);

      if (!option && !othervalue) {
        throw new Error(`Unknown value: ${rvalue}`);
      }

      let res: SelectActionResponse = {
        type: 'select',
        value: '',
        option: {
          text: '',
          value: '',
        },
      };

      if (option) {
        res = {
          type: 'select',
          value: option?.text ? option?.text : othervalue,
          option,
        };
      }

      if (othervalue) {
        res = {
          type: 'select',
          value: othervalue,
          option: {
            text: othervalue,
            value: othervalue,
          },
        };
      }

      if (res) chatCtl.setActionResponse(actionRequest, res);
    },

    [actionRequest, chatCtl, rvalue],
  );

  const setResponse = React.useCallback(
    (value: string): void => {
      // eslint-disable-next-line eqeqeq
      const option = actionRequest.options.find((o) => o.value == value);
      if (!option) {
        throw new Error(`Unknown value: ${value}`);
      }
      setTValue('');
      const res: SelectActionResponse = {
        type: 'select',
        value: option.text,
        option,
      };
      setRValue(value);
      // eslint-disable-next-line eqeqeq
      const hasOther = actionRequest.options.find((o) => o.text.trim() === '');
      if (!hasOther) chatCtl.setActionResponse(actionRequest, res);
    },
    [actionRequest, chatCtl],
  );

  return (
    <Box style={{ flexDirection: 'column', width: '100%' }}>
      <Box
        className="selectcontainer"
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          '& > *': {
            flex: '0 0 auto',
            maxWidth: '100%',
          },
          '& > * + *': {
            mt: 1,
          },
        }}
      >
        {/* <Box>{rvalue}</Box> */}
        {actionRequest.options.map((o) =>
          o.text.trim() !== '' ? (
            <Button
              key={actionRequest.options.indexOf(o)}
              type="button"
              value={o.value}
              onClick={(): void => setResponse(o.value)}
              // variant="outlined"
              variant={rvalue === o.value ? 'contained' : 'outlined'}
              color="primary"
              className="single-button"
            >
              {o.text}
            </Button>
          ) : (
            <TextField
              key={`ot${actionRequest.options.indexOf(o)}`}
              multiline
              placeholder="Anders, namelijk..."
              // inputProps={{ onKeyUp: handleKeyDown }}
              onChange={(e): void => setOther(e.currentTarget.value)}
              value={tvalue || ''}
              variant="outlined"
              maxRows={10}
            />
          ),
        )}
      </Box>
      {actionRequest.options.find((o) => o.text.trim() === '') && (
        <Button
          type="button"
          style={{ flex: 1, width: '100%', paddingTop: 10, paddingBottom: 10 }}
          // onClick={(e): void => setOther(e.currentTarget.value)}
          onClick={(): void => sendResponse(rvalue, tvalue)}
          // disabled={rvalue.length === 0}
          variant="contained"
          color="primary"
          startIcon={<Icon>send</Icon>}
        >
          Send
        </Button>
      )}
    </Box>
  );
}
