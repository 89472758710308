import { Box, Button, Icon, TextField } from '@mui/material';
import React from 'react';

import { ChatController } from '../chat-controller';
import { MultiTextCheckboxActionRequest } from '../chat-types';

export function MuiMultiTextCheckbox({
  chatController,
  actionRequest,
}: {
  chatController: ChatController;
  actionRequest: MultiTextCheckboxActionRequest;
}): React.ReactElement {
  const chatCtl = chatController;
  const [values, setValues] = React.useState<string[]>([]);
  const [valueText, setValueText] = React.useState('');

  const handleSelect = React.useCallback(
    (value: string): void => {
      if (!values.includes(value)) {
        setValues([...values, value]);
      } else {
        setValues(values.filter((v) => v !== value));
      }
      setValueText('');
    },
    [values],
  );

  const setResponse = React.useCallback((): void => {
    const options = actionRequest.options.filter((o) =>
      values.includes(o.value),
    );

    const res: MultiTextCheckboxActionRequest = {
      type: 'multitextcheckbox',
      value: `${valueText} ${options.map((o) => o.text).toString()}`,
      options,
    };
    chatCtl.setActionResponse(actionRequest, res);
    setValues([]);
  }, [actionRequest, chatCtl, values, valueText]);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
      if (e.nativeEvent.isComposing) {
        return;
      }

      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        setResponse();
      }
      setValues([]);
    },
    [setResponse],
  );

  const sendButtonText = actionRequest.sendButtonText
    ? actionRequest.sendButtonText
    : 'Verstuur';

  return (
    <Box
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
          flex: '0 0 auto',
          maxWidth: '100%',
        },
        '& > * + *': {
          mt: 1,
        },
      }}
    >
      {actionRequest.options.map((o) => (
        <Box
          key={`b${actionRequest.options.indexOf(o)}`}
          flexDirection="column"
          display="flex"
        >
          <Button
            key={actionRequest.options.indexOf(o)}
            type="button"
            value={o.value}
            onClick={(e): void => handleSelect(e.currentTarget.value)}
            variant={!values.includes(o.value) ? 'outlined' : 'contained'}
            color="primary"
          >
            {o.text}
          </Button>
          <TextField
            value={valueText}
            key={`${actionRequest.options.indexOf(o)}t`}
            onChange={(e): void => setValueText(e.target.value)}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            multiline
            inputProps={{ onKeyDown: handleKeyDown }}
            variant="outlined"
            maxRows={10}
          />
        </Box>
      ))}
      <Button
        type="button"
        onClick={setResponse}
        disabled={values.length === 0 && valueText?.length === 0}
        variant="contained"
        color="primary"
        startIcon={<Icon>send</Icon>}
      >
        {sendButtonText}
      </Button>
    </Box>
  );
}
